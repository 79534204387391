
import {
    defineComponent, PropType, ref,
    watch, Ref, onMounted
} from 'vue';
import importProgress from '@/components/view/common/propgress';
import { isElement } from '@/components/common/tip/util';
import ErrorDialog from '@/components/view/common/import-tip/error-dialog.vue';
import residentApi from '@/api/pm/resident';
import { ImportDetail, ImportStatus } from './types/index';
import ImportDetailDialog from './components/import-detail-dialog.vue';

const liHeight = 68;
export default defineComponent({
    components: {
        importProgress,
        ImportDetailDialog,
        ErrorDialog
    },
    props: {
        faceTaskList: {
            type: Object as PropType<ImportDetail>,
            required: true
        }
    },
    setup(props) {
        // 控制面板展开收起
        const unfold = ref(false);
        const statusIcon = {
            loading: require('@/assets/image/import-loading.png'),
            loadingWarn: require('@/assets/image/progress-warn.png'),
            success: require('@/assets/image/progress-success.png'),
            warn: require('@/assets/image/progress-warn.png')
        };
        const importStatus = ref<ImportStatus>('loading');

        // 内容高度
        const contentHeight = ref(0);
        const ulRef: Ref< HTMLElement | null > = ref(null);

        watch(props.faceTaskList, () => {
            console.log('faceTasklist, ', props.faceTaskList);
            // 还有正在进行导入得任务
            if (props.faceTaskList.CompletedTaskNum !== props.faceTaskList.Total) {
                importStatus.value = 'loading';
                props.faceTaskList.List.forEach((item) => {
                    if (item.Status === '2' || item.Status === '4') {
                        importStatus.value = 'loadingWarn';
                    }
                });
            } else {
                // 还有进行中或者导入中任务的
                importStatus.value = 'success';
                props.faceTaskList.List.forEach((item) => {
                    // 导入失败得情况
                    if (item.Status === '2' || item.Status === '4') {
                        importStatus.value = 'warn';
                    }
                });
            }

            // 计算当前导入列表高度
            if (isElement(ulRef.value)) {
                contentHeight.value = liHeight * props.faceTaskList.List.length;
                if (contentHeight.value > 340) {
                    contentHeight.value = 340;
                }
            }
        }, {
            immediate: true,
            deep: true
        });

        onMounted(() => {
            // 计算当前导入列表高度
            if (isElement(ulRef.value)) {
                contentHeight.value = liHeight * props.faceTaskList.List.length;
                if (contentHeight.value > 340) {
                    contentHeight.value = 340;
                }
            }
        });

        // 展示错误详情
        const isShowDetailDialog = ref(false);
        const taskInfoId = ref('');
        const isShowErrorInfoDialog = ref(false);
        const taskErrorInfo = ref('');
        // 6.5.3新增导入住户，区分错误弹窗
        function showDetail(taskItem: ImportDetail['List'][0]) {
            if (taskItem.Type === 'face') {
                taskInfoId.value = taskItem.ID;
                isShowDetailDialog.value = true;
            } else if (taskItem.Type === 'resident') {
                taskErrorInfo.value = taskItem.ErrorDetail || '';
                isShowErrorInfoDialog.value = true;
                // 置为已读
                residentApi.setDealImportTask({
                    ImportProjectTaskUUIDs: taskItem.ID
                }, [() => false, false]);
            }
        }

        return {
            // showError,
            // errorDetails,
            // isShowErrorDialog,
            unfold,
            ulRef,
            contentHeight,
            statusIcon,
            importStatus,
            showDetail,
            taskInfoId,
            isShowDetailDialog,
            isShowErrorInfoDialog,
            taskErrorInfo
        };
    }
});
