import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circle_tip = _resolveComponent("circle-tip")
  const _component_list = _resolveComponent("list")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.$wordList.ProperAllTextImportDetails,
    footerType: "customize",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_list, {
        headers: _ctx.header,
        "show-check": false,
        action: false,
        "request-data": _ctx.requestData,
        update: _ctx.updateList,
        class: "max-height600px overflow-auto"
      }, {
        ImportReslutSlot: _withCtx((data) => [
          (data.scope.ImportReslut === '0')
            ? (_openBlock(), _createBlock(_component_circle_tip, {
                key: 0,
                type: "success"
              }))
            : (_openBlock(), _createBlock(_component_circle_tip, {
                key: 1,
                type: "disable"
              }))
        ]),
        _: 1
      }, 8, ["headers", "request-data", "update"])
    ]),
    _: 1
  }, 8, ["title"]))
}