
import { defineComponent, ref, reactive } from 'vue';
import List, { RequestData } from '@/components/common/list';
import CircleTip from '@/components/common/tip/circle.vue';

export default defineComponent({
    emits: ['close'],
    components: {
        CircleTip,
        List
    },
    props: {
        taskId: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const header = [{
            name: 'PicFileName',
            label: WordList.Photo
        }, {
            name: 'ImportReslut',
            label: WordList.MulListUpdateListTanleStatus,
            type: 'customize'
        }, {
            name: 'ImportErrMsg',
            label: WordList.Details
        }];
        const updateList = ref(0);
        const requestData: RequestData = reactive({
            url: 'v3/web/community/face/getFaceTaskDetail',
            param: {
                ID: props.taskId
            }
        });

        return {
            header,
            updateList,
            requestData
        };
    }
});
